// 学生的路由配置
export default [
   // 学生登录页面
   {
      path: '/student/Login',
      name: 'student_Login',
      component: () => import('@/views/student/login.vue'),
      meta: {
         title: '学生登录',
         userType: 'student',
         isNeedTeaLogin: false
      }
   },
   // 学生对接界面
   {
      path: '/student/live',
      name: 'student_live',
      component: () => import('@/views/student/courseJoint.vue'),
      meta: {
         title: '',
         userType: 'student',
         isNeedTeaLogin: false
      }
   },
   // 直播观看界面
   {
      path: '/student/courseLive',
      name: 'student_courseLive',
      component: () => import('@/views/student/courseLive.vue'),
      meta: {
         title: '直播课程',
         userType: 'student',
         isNeedTeaLogin: true
      },
   },
   // 加专题直播
   {
	   path: '/student/courseLiveTopic',
	   name: 'student_courseLiveTopic',
	   component: () => import('@/views/student/courseLiveTopic.vue'),
	   meta: {
	      title: '中国建造（2024）管理创新论坛',
	      userType: 'student',
	      isNeedTeaLogin: true
	   },
   },
   // 学生信息 - 未提供
   {
      path: '/student/studentInfo',
      name: 'student_studentInfo',
      component: () => import('@/views/student/studentInfo.vue'),
      meta: {
         title: '个人信息',
         userType: 'student',
         isNeedTeaLogin: true
      },
   },
	// 学生登录后展示页面，公用模板页面
	{
      path: '/student',
      name: 'student',
      component: () => import('@/views/student/layout.vue'),
      meta: {
         title: '学生布局页',
         userType: 'student',
         isNeedTeaLogin: true,
      },
      children: [
         // 课程列表
         {
            path: 'index/:type',
            name: 'student_index',
            component: () => import('@/views/student/index.vue'),
            meta: {
               title: '直播课程',
               userType: 'student',
               isNeedTeaLogin: true
            },
         },
         // 课程详情 - 未提供
         {
            path: 'courseDetail',
            name: 'student_coureseDetail',
            component: () => import('@/views/student/courseDetail.vue'),
            meta: {
               title: '课程详情-学生',
               userType: 'student',
               isNeedTeaLogin: true
            },
         },
      ]
   },
]
