import ruiTool from "@/utils/ruiTool";

export default {
	namespaced: true,
	state() {
		return {
			setDevice: {
				//是否已选择
				isSelected: false,
				//选中摄像头
				selectedCamera: '',
				//选中麦克风
				selectedPhone: '',
			}
		}
	},
	mutations: {
		// 保存直播设备信息
		saveSelected(state, value) {
			state.setDevice.isSelected = true;
			state.setDevice.selectedCamera = value.selectedCamera;
			state.setDevice.selectedPhone = value.selectedPhone;
			localStorage.setItem('setDevice', JSON.stringify(state.setDevice));
		},
		// 清楚直播设备数据
		clearSelected(state) {
			state.setDevice = null;
			localStorage.removeItem('setDevice');
		}
	},
	getters: {
		// 获取选择的信息
		deviceInfo(state) {
			let result = null;
			try {
				const value = localStorage.getItem('setDevice');
				result = JSON.parse(value);
			} catch (e) {
				console.error(e.message)
			}
			if (ruiTool.isNull(result)) {
				result = {
					isSelected: false,
				}
			}
			return result;
		}
	},
	actions: {},
}
