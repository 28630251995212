import teacher from '@/router/config/teacher'
import student from '@/router/config/student'
import studentPhone from '@/router/config/studentPhone'

// 定义主要的路由配置
const routes = [{
		path: '/',
		name: 'index',
		component: () => import('@/views/index.vue'),
		meta: {
			title: '平台首页',
			isNeedTeaLogin: false
		}
	},
	// 解构教师路由元素
	...teacher,
	// 解构学生路由元素
	...student,
   // 解析学生手机端路由元素
   ...studentPhone,
]

console.info('路由配置', routes)

export default routes
