// 教师的路由配置
export default [
   // 教师登录页面
   {
      path: '/teacher/Login',
      name: 'teacher_Login',
      component: () => import('@/views/teacher/login.vue'),
      meta: {
         title: '教师登录',
         userType: 'teacher',
         isNeedTeaLogin: false
      }
   },
   // 教师对接接口
   {
      path: '/teacher/live',
      name: 'teacher_live',
      component: () => import('@/views/teacher/courseJoint.vue'),
      meta: {
         title: '授课界面',
         userType: 'teacher',
         isNeedTeaLogin: false
      }
   },
   // 直播设备设置
   {
      path: '/teacher/courseLiveSet',
      name: 'teacher_courseLiveSet',
      component: () => import('@/views/teacher/courseLiveSet.vue'),
      meta: {
         title: '直播设备配置',
         userType: 'teacher',
         isNeedTeaLogin: true
      },
   },
   // 教师直播界面
   {
      path: '/teacher/courseLive',
      name: 'teacher_courseLive',
      component: () => import('@/views/teacher/courseLive.vue'),
      meta: {
         title: '课程直播-教师',
         userType: 'teacher',
         isNeedTeaLogin: true
      },
   },
   // 教师直播界面腾讯云直播
   {
      path: '/teacher/courseLiveTx',
      name: 'teacher_courseLiveTx',
      component: () => import('@/views/teacher/courseLiveTx.vue'),
      meta: {
         title: '课程直播-教师-tx',
         userType: 'teacher',
         isNeedTeaLogin: true
      },
   },
   // 教师直播界面Obs
   {
      path: '/teacher/courseLiveObs',
      name: 'teacher_courseLiveObs',
      component: () => import('@/views/teacher/courseLiveObs.vue'),
      meta: {
         title: '课程直播-教师',
         userType: 'teacher',
         isNeedTeaLogin: true
      },
   },
   // 教师信息
   {
      path: '/teacher/teacherInfo',
      name: 'teacher_teacherInfo',
      component: () => import('@/views/teacher/teacherInfo.vue'),
      meta: {
         title: '个人信息',
         userType: 'teacher',
         isNeedTeaLogin: true
      },
   },
   // 授课课程信息
   {
      path: '/teacher',
      name: 'teacher',
      component: () => import('@/views/teacher/layout.vue'),
      meta: {
         title: '教师布局页',
         userType: 'teacher',
         isNeedTeaLogin: true,
      },
      children: [
         // 课程列表
         {
            path: 'index/:type',
            name: 'teacher_index',
            component: () => import('@/views/teacher/index.vue'),
            meta: {
               title: '直播课程-教师',
               userType: 'teacher',
               isNeedTeaLogin: true
            },
         },
         // 课程详情
         {
            path: 'courseDetail',
            name: 'teacher_coureseDetail',
            component: () => import('@/views/teacher/courseDetail.vue'),
            meta: {
               title: '课程详情-教师',
               userType: 'teacher',
               isNeedTeaLogin: true
            },
         },
      ]
   },
]
