import { ElMessage } from 'element-plus';
import md5 from 'js-md5'
// 常用封装的封装
const ruiTool = {
   // 判断是否空值,如果是空值,则返回true 
   isNull(val) {
      var isNull = false;
      if (typeof (val) == undefined)
         isNull = true;
      if (val == null)
         isNull = true;
      if (val == "")
         isNull = true;
      return isNull;
   },
   // 判断是否非空值,如果是非空值,则返回true 
   isNotNull(val) {
      return !ruiTool.isNull(val);
   },
   // 显示错误提示
   showError(msg) {
      ElMessage.error(msg);
   },
   // 显示消息提示
   showInfo(msg) {
      ElMessage.info(msg);
   },
   // 转为MD5加密
   toMd5(value) {
      return md5(value);
   },
   // 获取url参数中某参数的值
   getUrlPara(url, paraName) {
      const index = url.indexOf('?')
      let paraStr = url.substr(index + 1)
      let arrayPara = paraStr.split('&')
      let result = null;
      for (let i = 0; i < arrayPara.length; i++) {
         let arr = arrayPara[i].split('=')
         console.info(arr[0], arr[0] == paraName)
         if (arr[0] == paraName) {
            result = arr[1];
            break;
         }
      }
      return result
   },
   // 判断设备类型
   isMobile() {
      const flag = navigator.userAgent.match(
         /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      if (flag) {
         console.log('移动端打开')
         return true;
      } else {
         console.log('PC端打开')
         return false;
      }
   },
   // 页面跳转,同一个页面打开
   redirect(url) {
      window.open(url,'_self');
   }
}

export default ruiTool
