import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import { net_ApiUrl, net_requestTimeout } from '@/utils/ruiConfig'

// 创建axios对象
const reqInstance = axios.create({
	baseURL: net_ApiUrl,
	timeout: net_requestTimeout,
	withCredentials: false
})

// 请求拦截器
reqInstance.interceptors.request.use(config => {
	// console.info("请求拦截器1", config)
	// 在发送请求之前
	const token = store.getters['user/token']
	// console.info('token', token)
	if (token) {
		config.headers.Authorization = token
	}
	return config
}, error => {
	console.info("请求拦截器2")
	// 请求错误进行处理
	return Promise.reject(error)
})

// 回应拦截器
reqInstance.interceptors.response.use(response => {
	// console.info("回应拦截器1", response)
	return response.data
}, error => {
	console.info("回应拦截器2")
	return Promise.reject(error)
})

export default reqInstance
