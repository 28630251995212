module.exports = {
   // 开发环境
   //net_ApiUrl: 'http://localhost:9083/',
   //netty连接地址，不用了
   //net_WsUrl: 'ws://localhost:9084/ws',
   // 上线环境
   net_ApiUrl: 'https://livetshow.a6edu.com:9073/',
   //netty连接地址，不用了
   //net_WsUrl: 'wss://livetshow.a6edu.com:9074/ws',

   //mqtt服务器
   net_WsUrl: ' wss://mqttlive.a6edu.com:8084/mqtt',
   // 超时时间
   net_requestTimeout: 10000,
   // 心跳检查时间间隔,5秒一次。
   pingCheckTime: 5000,
   // 未收到消息间隔,60秒
   pingReceiveTime: 60,
}
