// 学生的路由配置
export default [
	// 直播观看界面
	{
		path: '/studentPhone/courseLive',
		name: 'studentPhone_courseLive',
		component: () => import('@/views/studentPhone/courseLive.vue'),
		meta: {
			title: '直播课程',
			userType: 'student',
			isNeedTeaLogin: true
		},
	},
	// 直播专题界面
	{
		path: '/studentPhone/courseLiveTopic',
		name: 'studentPhone_courseLiveTopic',
		component: () => import('@/views/studentPhone/courseLiveTopic.vue'),
		meta: {
			title: '中国建造（2024）管理创新论坛',
			userType: 'student',
			isNeedTeaLogin: true
		},
	},
]
