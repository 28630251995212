// 定义排查的路由，未使用
const ignore = {
	//根据路由名称匹配
	names: ['404', '403'],
	//根据路由fullPath匹配
	paths: ['/login'],
	/**
	 * 判断路由是否包含在该配置中
	 * @param route vue-router 的 route 对象
	 * @returns {boolean}
	 */
	includes(route) {
		return this.names.includes(route.name) || this.paths.includes(route.path)
	}
}

export default ignore
