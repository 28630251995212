import {
	createStore
} from 'vuex'

import userModule from './modules/user'
import liveModule from './modules/live'

export default createStore({
	state() {

	},
	mutations: {

	},
	getters: {

	},
	actions: {

	},
	modules: {
		user: userModule,
		live: liveModule
	}
})
