import Vue from 'vue'
import { ElMessage } from 'element-plus';
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import qs from 'qs'
import ruiTool from '@/utils/ruiTool'
import reqInstance from '@/utils/ruiAxios'

// ajax请求封装
const ruiAjax = {
   // 获取请求数据
   get(url, para, success, loading = true) {
      console.info('请求参数', para)
      reqInstance({
         url: url,
         method: 'get',
         params: para,
         loading: loading,
         headers: {
            'content-type': 'application/json;charset=utf-8'
         }
      }).then(res => {
         console.info('请求回应', res)
         if (ruiTool.isNull(res))
            ElMessage.error('网路请求出错了')
         else if (res.code == 200)
            success(res);
         else
            ElMessage.error(res.msg)
      })
   },
   // 提交请求数据，
   post(url, para, success, loading = true) {
      reqInstance({
         url: url,
         method: 'post',
         data: qs.stringify(para, {
            arrayFormat: 'repeat'
         }),
         loading: loading,
         headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
         },
      }).then(res => {
         if (ruiTool.isNull(res))
            Message.error('网路请求出错了')
         else if (res.code == 200)
            success(res);
         else
            Message.error(res)
      })
   },
   // 提交json数据,后台@requestBody注解
   postJson(url, para, success, loading = true) {
      reqInstance({
         url: url,
         method: 'post',
         data: para,
         loading: loading,
         headers: {
            'content-type': 'application/json;charset=utf-8'
         }
      }).then(res => {
         if (ruiTool.isNull(res))
            Message.error('网路请求出错了')
         else if (res.code == 200)
            success(res);
         else
            Message.error(res)
      })
   },
   // 文件上传
   postFile(url, files, success, loading = true) {
      var formdate = new FormData();
      if (files == undefined) {
         Message.error('请选择上传文件')
         return;
      }
      for (var i = 0; i < files.length; i++) {
         formdate.append('fileUpload', files[i]);
      }
      reqInstance({
         url: url,
         method: 'post',
         data: formdate,
         loading: loading,
         headers: {
            'content-type': 'multipart/form-data'
         },
      }).then(res => {
         if (ruiTool.isNull(res))
            Message.error('网路请求出错了')
         else if (res.code == 200) {
            Message.info('上传成功')
            success(res);
         } else
            Message.error(res)
      })
   }
}

export default ruiAjax
