import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入elementuiPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

// 引入axios
import axios from 'axios'
import VueAxios from 'vue-axios'

let jweixin = require('jweixin-module')

const app = createApp(App)

app.config.globalProperties.$wx = jweixin;

// 导入elementPlus
app.use(ElementPlus, {
	locale: zhCn,
})
// 导入Axios
app.use(VueAxios, axios)

// 导入vuex，router
app.use(store).use(router).mount('#app')
