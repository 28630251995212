import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import routes from '@/router/config'
import ignore from '@/router/ignore'
import ruiTool from '@/utils/ruiTool'

// 创建路由对象
const router = createRouter({
	history: createWebHashHistory(),
	routes
})

// 导航前置守卫
router.beforeEach((to, from, next) => {
	// 设置页面标题
	document.title = to.meta.title

	// 不需要登录，则直接返回
	if (to.meta.isNeedTeaLogin != true)
	{
		next();
		return;
	}

	console.info('qqqq', to)
	console.info('访问路径', to.path)
	console.info('导航前置守卫', to.meta)

	// 教师身份登录检测
	if (to.meta.userType == 'teacher') {
		const userInfo = store.getters['user/userInfoTea'];
		if (ruiTool.isNull(userInfo))
		{
			next({ path: '/teacher/Login' })
			return;
		}
	}
	// 学生身份登录检测
	if (to.meta.userType == 'student') {
		const userInfo = store.getters['user/userInfoStu'];
		if (ruiTool.isNull(userInfo)){
			next({ path: '/student/Login' })
			return;
		}
	}

	// 如果直接访问/teacher,则直接进入/teacher/index/wait
	if (to.path == '/teacher'){
		next({ path: '/teacher/index/wait' })
		return;
	}

	// 如果直接访问/student,则直接进入/student/index/wait
	if (to.path == '/student')
	{
		next({ path: '/student/index/wait' })
		return;
	}

	// if(from.path == '/studentPhone/courseLive' ) 
	// {
	// 	window.location.href = "http://meetest.a6edu.com/pages/meeting/video_list?meeting_code=54081659940219";
	// 	return;
	// }

	// if(from.path == '/student/courseLive' ) 
	// {
	// 	window.location.href = "http://meetest.a6edu.com/pages/meeting/video_list?meeting_code=54081659940219";
	// 	return;
	// }

	next()
})
// 导航解析守卫
router.beforeResolve(async to => {
	// console.info('导航解析守卫', to.meta)
})
// 导航后置守卫
router.afterEach((to, from) => {
	// console.info('导航后置守卫', to.meta)
})

export default router
