<template>
	<router-view />
</template>
<script>
	import ruiAjax from '@/utils/ruiAjax'
	import ruiTool from '@/utils/ruiTool'

	export default {
		name: 'App',
		methods: {

		},
		mounted() {

		}
	}
</script>
<style>
</style>
