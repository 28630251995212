import ruiTool from "@/utils/ruiTool";

export default {
	namespaced: true,
	state() {
		return {
			// 教师信息
			userInfoTea: {
				orgCode:'',
				userCode: '',
				userName: '',
				token: '',
			},
			// 学生信息
			userInfoStu: {
				orgCode:'',
				userCode: '',
				userName: '',
				token: '',
			},
			// 登录凭证
			token:'',
			//标识对接跳转过来后，是否已进入播放页面
			isLive:'否'
		}
	},
	mutations: {
		//设置Live
		saveLive(state,isLive){
			state.isLive = isLive;
		},
		// 保存教师信息
		saveUserInfoTea(state, userInfo) {
			console.info('保存用户信息')
			state.userInfoTea = userInfo;
			localStorage.setItem('userInfoTea', JSON.stringify(state.userInfoTea));
			// 保存token
		},
		// 清理教师信息
		clearUserInfoTea(state) {
			state.userInfoTea = null;
			localStorage.removeItem('userInfoTea');
		},
		// =============================================================================
		// 保存学生信息
		saveUserInfoStu(state, userInfo) {
			state.userInfoStu = userInfo;
			localStorage.setItem('userInfoStu', JSON.stringify(state.userInfoStu));
		},
		// 清理学生信息
		clearUserInfoStu(state) {
			state.userInfoStu = null;
			localStorage.removeItem('userInfoStu');
		},
	},
	getters: {
		//获取Live
		getLive(state){
			return state.isLive;
		},
		// 获取教师信息
		userInfoTea(state) {
			let result = '';
			try {
				const userInfo = localStorage.getItem('userInfoTea');
				result = JSON.parse(userInfo)
			} catch (e) {
				console.error(e.message)
			}
			return result;
		},
		// 获取学生信息
		userInfoStu(state) {
			let result = '';
			try {
				const userInfo = localStorage.getItem('userInfoStu');
				result = JSON.parse(userInfo)
			} catch (e) {
				console.error(e.message)
			}
			return result;
		}
	},
	actions: {},
}
